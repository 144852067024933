<template>
  <div
    class="Expander my-1 mx-auto relative"
  >
    <div
      class="Expander__trigger transition duration-500 rounded-full overflow-hidden border-primary border-2 bg-primary text-white hover:text-primary z-10"
      @click="
        opacity0 = false;
        showContent();
      "
      :class="open ? 'active' : 'beforeBorder'"
    >
      {{ title }}
      <svg
        class="Expander__trigger-Icon"
        :class="{ open: open }"
        width="40"
        height="12"
        stroke="cornflowerblue"
      >
        <polyline
          points="12,2 20,10 28,2"
          stroke-width="3"
          fill="none"
        ></polyline>
      </svg>
    </div>
    
    <div
      ref="expander-body"
      class="relative Expander__body z-0 overflow-hidden min-w-full"
      :class="[opacity0 ? 'opacity-0' : 'duration-300']"
    >
      <div :class="[hasStarted ? 'absolute top-0 left-0 w-full h-full' : 'opacity-0']">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Expander",
  props: {
    title: {
      type: String,
      default: "title"
    },
    animation: {
      type: String,
      default: "rightToLeft"
      // validator: prop => ['leftToRight', 'bounceIn', 'bottomToTop'].includes(prop)
    },
    delay: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      open: false,
      baseHeight: 0,
      bodyHeight: 0,
      hasStarted: false,
      opacity0: true
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      self.setInitValues();
      self.hasStarted = true;
    }, 50);
  },
  methods: {
    setInitValues() {
      if (this.open) {
        this.$refs["expander-body"].style.height = this.height + "px";
      } else {
        try {
          this.$refs["expander-body"].style.height = "0px";
        } catch (e) {
          console.log(e);
        }
      }
    },
    showContent() {
      this.open = !this.open;
      if (this.open) {
        this.$refs["expander-body"].style.height = this.height + "px";
      } else {
        this.$refs["expander-body"].style.height = "0px";
      }
    }
  }
};
</script>
<style >
.beforeBorder {
  position: relative;
}

.beforeBorder:before {
  transition: opacity 0.1s linear, transform 0.5s ease-in-out;
  position: absolute;
  border-bottom: 1px solid currentColor;
  content: "";
  width: 100%;
  left: 0;
  bottom: -1px;
}

.beforeBorder:not(:hover)::before {
  transform: scaleX(0);
  opacity: 0;
}

.Accordion {
  overflow: hidden;
}

.Expander__trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.7rem 0.5rem;
}

.Expander__trigger:hover {
  background: transparent;
  svg {
    stroke: var(--primary);
  }
}

.Expander__trigger-Icon {
  stroke: #fff;
  position: absolute;
  right: 20px;
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate(-90deg);
}

.Expander__trigger-Icon.open {
  stroke: #c9e5b1;
  transform: rotate(0deg);
}

.Expander__body {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.11, 0.26, 0.16, 0.46);
  padding: 0;
  display: flex;
  justify-content: center;
}

.rightToLeft-enter-active {
  animation: rightToLeft 0.5s;
}

.rightToLeft-leave-active {
  animation: rightToLeft 0.5s reverse;
}

.leftToRight-enter-active {
  animation: leftToRight 0.5s;
}

.leftToRight-leave-active {
  animation: leftToRight 0.5s reverse;
}

.bounceIn-enter-active {
  animation: bounceIn 0.3s;
}

.bounceIn-leave-active {
  animation: bottomToTop 0.2s reverse;
}

.bottomToTop-enter-active {
  animation: bottomToTop 0.5s forwards;
}

.bottomToTop-leave-active {
  animation: bottomToTop 0.5s reverse;
}

@-moz-keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rightToLeft {
  0% {
    transform: translateX(100vw);
  }

  50% {
    transform: translateX(-2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes leftToRight {
  0% {
    transform: translateX(-100vw);
  }

  50% {
    transform: translateX(2em);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@-o-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@-moz-keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@-o-keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
